<template>
  <v-autocomplete
    :ref="refer"
    item-color="primary"
    :items="items"
    :label="label"
    :item-text="text"
    multiple
    :item-value="value"
    v-model="object.locations"
    :rules="rules"
    @mouseup="checkState"
    small-chips
    persistent-hint
    outlined
    dense
    color="primary"
    clearable
    :loading="loading_prop"
    :disabled="disabled_prop"
    :return-object="returnObject"
    @click:clear="$emit('click:clear', $event)"
    @update:search-input="$emit('update:storages', $event)"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip small v-if="index === 0">
        <span>{{ item[text] }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey&#45;&#45;text caption"
        >(+{{ object.locations.length - 1 }}
        {{ $t('common.titles.others') }})</span
      >
    </template>
    <template v-slot:item="{ item, attrs }">
      <div style="max-width: 360px">
        <v-checkbox
          :input-value="attrs.inputValue"
          color="primary"
          class="pt-2 pb-2 mt-0"
          :hide-details="true"
        >
          <template v-slot:label>
            <read-more
              :classes="'mb-0'"
              :text="item[text]"
              :max-chars="80"
              :font-size="14"
            />
          </template>
        </v-checkbox>
      </div>
    </template>
    <template v-slot:prepend-item>
      <slot name="search-info"></slot>
      <v-checkbox
        v-model="checkboxState"
        color="primary"
        class="pt-2 pb-2 mt-0 pl-4"
        :hide-details="true"
        @change="selectAll"
      >
        <template v-slot:label>
          <v-btn small color="primary" x-small text>
            {{ $t('common.titles.selectAll') }}
          </v-btn>
        </template>
      </v-checkbox>
      <v-divider class="mb-2"></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
  import ReadMore from './ReadMoreComponent'
  import _ from "lodash";
  export default {
    name: 'AutocompleteLocations',
    props: {
      object: {
        type: Object,
        required: true
      },
      refer: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      rules: {
        type: Array,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      disabled_prop: {
        type: Boolean,
      },
      loading_prop: {
        type: Boolean,
      },
      returnObject: {
        type: Boolean,
        required: false
      },
    },
    components: {
      ReadMore,
    },
    data() {
      return {
        checkboxState: false
      }
    },
    methods: {
      checkState() {
        if (
          this.object.locations.length ===
          this.$refs[this.refer].filteredItems.length
        ) {
          this.checkboxState = true
        } else {
          this.checkboxState = false
        }
      },
      selectAll() {
        if (this.checkboxState === false) {
          this.$set(this.object, 'locations', [])
        } else {
          let aux = []
          if (this.object.locations.length > 0) {
            _.forEach(this.object.locations, object => {
              if (
                  !_.find(this.$refs[this.refer].filteredItems, o => {
                    return o.id === object.id
                  })
              )
                this.$refs[this.refer].filteredItems.push(object)
            })
            aux = this.$refs[this.refer].filteredItems
          } else {
            aux = this.$refs[this.refer].filteredItems
          }

          this.$set(this.object, 'locations', aux)
        }
      },
    },
  }
</script>
