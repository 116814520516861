<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation
          translation-key="authority.label"
          tag="h1"
        />
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="authorities"
      :loading="loading"
      :no-results-text="$t('common.ui.not_search_found')"
      :no-data-text="$t('common.ui.not_data_found')"
      :loading-text="$t('common.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('common.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('common.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
              <UserDataComponent
                :edited-index="editedIndex"
                :langs="langs"
                :dialog="dialog"
                @dialog:action="dialog = $event"
                @update:item="updateItem($event)"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              class="mr-2"
              small
              @click="getProcessByAuthority(item)"
            >
              mdi-all-inclusive
            </v-icon>
          </template>
          <span>
            {{
              ($t("common.buttons.assign") + " " + $t("process.label"))
                | toFormatCase("upper")
            }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("authority.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              class="mr-2"
              small
              @click="getLocations(item)"
            >
              mdi-map-marker-check
            </v-icon>
          </template>
          <span>
            {{
              ($t("common.buttons.assign") +
                " " +
                $t("locations.label"))
                | toFormatCase("upper")
            }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("authority.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="
                check([{ domain: 'PersonInCharge', permisions: ['Update'] }])
              "
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("authority.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="
                check([{ domain: 'PersonInCharge', permisions: ['Delete'] }])
              "
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("authority.label") }}
            </span>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showDialogProcess" persistent width="600px">
        <v-card :light="true" color="white">
           <v-overlay class="ml-3" :value="overlayProcess" absolute color="white">
                <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                />
            </v-overlay>
          <v-card-title class="headline secondary t-bw-secondary--text px-6">{{ $t("authority.titles.authority_process") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-6">
            <v-container>
              <v-data-table
                checkbox-color="primary"
                :headers="headersProcess"
                :items="proccess"
                item-key="id"
                v-model="editedItem.process"
                show-select
                :items-per-page="5"
                :loading="loading"
                :no-data-text="$t('common.ui.not_data_found')"
                :loading-text="$t('common.notification.loadData')"
                :footer-props="{
                  itemsPerPageText: $t('common.table.itemsPerPageText'),
                  pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                }"
              >
                <template v-slot:item.language_key="{ item }">
                  {{ translate(item.language_key, true) }}
                </template>
                <template v-slot:item.sdt_to_show="{ item }">
                  {{ translate(item.sdt_to_show.language_key, true) }}
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              small text color="neuter"
              @click="closeAuthorityProcess"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small elevation="0" color="primary" class="t-bw-primary--text"
              @click="assign_process"
            >
              {{ $t("common.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!--        Authority Locations-->
    <v-row justify="center">
      <GrantAccessLocationComponent v-if="showDialogLocations" :edited-item="editedItem" :dialog="showDialogLocations" @dialog:action="showDialogLocations = $event" />
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import AuthorityService from "../../../services/AuthorityService";
import LanguajeSelect from "@/components/LanguajeSelect";
import LanguajeService from "../../../services/LanguajeService";
import PermisionService from "../../../services/PermisionService";
import sortList from '@/services/OrderListService'
import GrantAccessLocationComponent from "@/module/configuration/authority/components/GrantAccessLocationComponent";
import UserDataComponent from "@/module/configuration/authority/components/UserDataComponent";

export default {
  components: { GrantAccessLocationComponent, LanguajeSelect, UserDataComponent },
  data: () => ({
    filterStorageDevice: [],
    langs: [],
    profile: null,
    editedIndex: -1,
    search: "",
    editedItem: {
      name: "",
      company_id: null,
      password: "",
      email: "",
      document_key: "",
      document_private: "",
      revoke: [],
      grant: [],
      language: "",
      roles: [],
      process: [],
    },
    defaultItem: {
      process: [],
    },
    dialog: false,
    showDialogProcess: false,
    showDialogLocations: false,
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 40,
    deletingItem: {},
    overlayProcess: false,
    overlayLocation: false,
    searchPrimary: null,
    searchSecondary: null
  }),

  computed: {
    authority: {
      get() {
        return this.$store.getters['authority/authority'];
      },
      set(value) {
        this.$store.commit("authority/SET_AUTHORITY", value);
      }
    },
    ...mapGetters({
      authorities: "authority/authorities",
      roles: "authority/roles",
      proccess: "proccess/proccess",
      authoritiesStoragesDevices: "authority/authoritiesStoragesDevices",
      // All locations
      locations: "locations/locationsList",
      // All storeDevices
      storageDevices: "storage/allStoragesByCompany",
      secondaryLocation: "storage/storagesByUserAccess",
      loading: "authority/loading",
      authoritiesProcess: "authority/authoritiesProcess",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
    }),
    primaryLocation: {
      get() {
        return this.$store.getters['locations/locationByUserAccess']
      },
      set(val) {
        this.$store.commit('locations/SET_LOCATION_BY_USER_ACCESS', val)
      }
    },
    headers: () => [
      { text: i18n.t("authority.fields.name"), value: "name" },
      { text: i18n.t("authority.fields.email"), value: "email" },
      {
        text: i18n.t("authority.fields.identificationType"),
        value: "document_key",
      },
      {
        text: i18n.t("authority.fields.identificationValue"),
        value: "document_private",
      },
      { text: i18n.t("authority.fields.language"), value: "language" },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        width: 150,
        sortable: false,
        align: "center",
      },
    ],
    headersStorageDevice: () => [
      { text: i18n.t("storage.fields.name"), value: "control_label" },
      {
        text: i18n.t("storage.fields.storage_type"),
        value: "storage_device_type_name",
      },
      {
        text: i18n.t("storage.fields.location"),
        value: "primary_location_name",
      },
    ],
    headersPrimaryLocation: () => [
      { text: i18n.t("storage.fields.location"), value: "name" },
    ],
    headersProcess: () => [
      { text: i18n.t("process.fields.name"), value: "language_key" },
      { text: i18n.t("process.fields.stages"), value: "sdt_to_show" },
    ],
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchAuthority: "authority/fetchAuthority",
      fetchListAuthority: "authority/fetchListAuthority",
      fetchListAuthorityRoles: "authority/fetchListAuthorityRoles",
      fetchListAuthorityProcess: "authority/fetchListAuthorityProcess",
      fetchListProccess: "proccess/fetchListProccess",
      saveAuthority: "authority/saveAuthority",
      pushProcess: "authority/pushProcess",
      pushLocations: "authority/pushLocations",
      fetchListAuthorityProcessEdit: "authority/fetchListAuthorityProcessEdit",
      fetchAccessLocationByUser: "locations/fetchAccessLocationByUser",
      fetchAccessStoragesByUser: "storage/fetchAccessStoragesByUser",
      fetchStorageTypesActiveByCompany: "storage_type/fetchStorageTypesActiveByCompany",
    }),
    orderDatas(list, order){
        return sortList.orderListByUppercase(list, order);
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key);
    },
    async getLocations(item) {
      this.editedItem = Object.assign({}, {
        ...item,
        revoke:[],
        grant: []
      });

      await this.$root.$emit("load:storages", item)

      this.showDialogLocations = true
    },
    updateItem() {
      this.dialog = false;
      this.editedIndex = -1;
      this.$store.commit("authority/RESTORE_AUTHORITY")
      this.editedItem = Object.assign({}, this.authority);
    },
    async editItem(item) {
      this.editedIndex = this.authorities.indexOf(item);
      this.authority = Object.assign({}, item);

      await this.fetchAuthority([item.id, this.$toast]).finally(() => {
        this.dialog = true;
      }).finally(() => {
        this.$root.$emit('load:authority', this.editedIndex)
      })
    },

    getStorageDeviceByLocation(event) {
      if (!event.value) {
        this.$set(event.item, 'isAllowed', false)

        let storages = _.filter(this.editedItem.storageDevice, { primaryLocationVersionIds: event.item.id })

        _.forEach(storages, (storage) => {
          this.$set(storage, 'isAllowed', false)
        })

        const secondary = _.filter(this.editedItem.storageDevice, { isAllowed: true })

        this.$set(this.editedItem, 'storageDevice', secondary)
      } else {
        this.$set(event.item, 'isAllowed', true)
      }
    },

    initialize() {
      this.langs = this.$i18n.availableLocales;
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.fetchListAuthority([this.profile, this.$toast]);
      this.fetchListAuthorityRoles([this.profile, this.$toast]);
      this.fetchListProccess([this.profile, this.$toast]);
    },

    deleteItem() {
      this.dialogClose();
      AuthorityService.delete(this.deletingItem.id)
        .then((resp) => {
          this.$toast.success(this.$t("authority.notification.deleted"), {
            icon: 'mdi-check-circle',
            queueable: true,
          });
        })
        .catch((err) => {
          if (err.message.includes("406")) {
            this.$toast.info(this.$t("authority.notification.inUse"), {
              icon: 'mdi-information-slab-circle',
              queueable: true
            });
          } else {
            this.$toast.error(this.$t("authority.notification.deletedError"), {
              queueable: true,
            });
          }
        })
        .finally(() => {
          this.fetchListAuthority([this.profile, this.$toast]);
        });
    },
    closeAuthorityProcess() {
      this.showDialogProcess = false;
    },
    async assign_process() {
      this.overlayProcess = true;
      const scope = this;
      let item = Object.assign({}, this.editedItem);
      let authority_process = [];
      _.forEach(this.editedItem.process, function(value) {
        if (value.id) {
          authority_process.push({
            person_in_charge_id: item.id,
            process_id: value.id,
          });
        }
      });

      await this.pushProcess([{
        personInChargeId: item.id,
        personInChargeProcessList: authority_process
      }, this.$toast]).finally(() => {
        if (item.id === this.profile.id) {
          this.fetchListAuthorityProcess([item.id, this.$toast]);
        } else {
          this.fetchListAuthorityProcessEdit([item.id, this.$toast]);
        }
        this.overlayProcess = false;
        this.closeAuthorityProcess();

      });
      await this.fetchStorageTypesActiveByCompany([this.$toast])
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },
    check(permisions) {
      return PermisionService.check(permisions);
    },
    getProcessByAuthority(item) {
      this.editedItem = Object.assign({}, item);
      this.fetchListAuthorityProcess([item.id, this.$toast]).finally(() => {
        this.editedItem.process = this.authoritiesProcess;
        this.showDialogProcess = true;
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style scoped></style>
